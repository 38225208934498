@import './fonts/stylesheet.css';

:root {
  --antd-wave-shadow-color: transparent;
  /* --antd-wave-shadow-color: #444; */
}

*,
html {
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 16px;
  font-family: 'Kanit' !important;
}

.error-box {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.error-box .ant-modal-content {
  width: 200px;
  height: 180px;
  background-color: rgba(14, 21, 32, 0.75);
  margin: 0 auto;
  border-radius: 10px;
  font-family: 'DB Helvethaica X 55 Regular';
}

.antd-img-crop-modal .ant-modal-footer .ant-btn {
  border-radius: 5px;
}

.ant-cascader-menus ul,
.ant-cascader-menus ol {
  max-width: 80vw;
  width: max-content !important;
}

.ant-cascader-menu-item {
  white-space: pre-wrap !important;
}

*::-webkit-scrollbar {
  width: 0px !important;
  display: none;
}

*::-webkit-scrollbar-track {
  box-shadow: none;
}

*::-webkit-scrollbar-thumb {
  box-shadow: none;
}

.ant-message .anticon {
  top: -3px !important;
}

.ant-select-item-option-content {
  white-space: break-spaces !important;
}

.anticon {
  vertical-align: 1px !important;
}


input.ant-input{
  border: 0px !important;
}